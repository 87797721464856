export const handleSubmit = (e) => {
    e.preventDefault();
    const form = e.target;
    const formSubmitData = new URLSearchParams(new FormData(e.target));
    formSubmitData.append('action', 'contact_form');
    formSubmitData.append('form_name', form.getAttribute('name'));
    fetch("https://szczurze-wzgorze.4beta.pl/wp-admin/admin-ajax.php", {
        method: "POST",
        body: formSubmitData
    })
    .then((response) => response.json())
    .then((data) => {
        if (data.status === 'success') {
            form.reset();
            form.parentNode.innerHTML = data.message;
        } else {
            form.parentNode.innerHTML = `Wystąpił błąd ${data.message}`;
        }
    })
    .catch(console.error)
}