import React from "react";

import { handleSubmit } from "../components/contactForm/formHandler";
import Layout from "../components/layout";
import Section from "../components/section/Section";
import Form from "../components/contactForm/Form";
import Contact from "../components/contact/Contact";

const PytajOCoChcesz = ({ data: { formData: { edges: formData } } }) => {

    return (
        <Layout post>
            <Section header={formData[0].node.title} hideHeader className="pt-lg-18">
                <Form title={formData[0].node.title}
                    subtitle={<span className="fs-1 fw-light">{formData[0].node.acf.subtitle}</span>}>
                    <div className="col-lg-8 d-flex align-items-center">
                        <form onSubmit={handleSubmit} name="ask-anything" className="form-ipt" method="post">
                            <p className="visually-hidden">
                                <label>Nie wypełniaj tego pola jeśli jesteś człowiekiem: <input name="bot-field" /></label>
                            </p>
                            <input name="name" className="form-ipt-small" type="text" required placeholder="Twoje imię" />
                            <input name="email" className="form-ipt-big" type="email" required placeholder="Twój adres e-mail" />
                            <textarea name="message" className="form-ipt-bigger" required placeholder="Treść wiadomości" />
                            <div className="d-flex flex-row form-spacer">
                                <input type="checkbox" id="rodo" name="rodo" required value="agreed" />
                                <label className="form-spacer-label" htmlFor="rodo"> Wyrażam zgodę na przetwarzanie
                                moich danych osobowych zgodnie z ustawą z dnia 29
                                sierpnia 1997 r. o ochronie danych osobowych (Dz.U. z 2014 r. poz. 1182 ze zm.) w
                                zakresie
                                    podanym w formularzu, w celu odpowiedzi na wysłaną przeze mnie wiadomość.</label>
                            </div>
                            <input className="form-ipt-submit btn btn-primary" value="Wyślij" type="submit" />
                        </form>
                    </div>
                    <div className="col-lg-4 d-none d-lg-block">
                        <Contact />
                    </div>
                </Form>
            </Section>
        </Layout>
    )
}


export const formQuery = graphql`
    query {
      formData: allWordpressPage(filter: {wordpress_id: {eq: 219}}) {
        edges {
         node {
            title
            acf {
              subtitle
            }
         }
        }
      }
    }
`;

export default PytajOCoChcesz
